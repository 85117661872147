<template>
    <div class="tab-text">
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>File</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input
                class="inputx"
                type="file"
                v-if="uploadReady"
                ref="file"
                name="file"
                accept=".xls, .xlsx"
                />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-button class="ml-auto mt-2" v-on:click="handleSubmit">Submit</vs-button>
                <vs-button
                    class="ml-4 mt-2"
                    type="border"
                    color="warning"
                    v-on:click="handleDownload"
                    >Download Template</vs-button>
            </div>
        </div>
        <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
        >
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item
                        v-for="item in table.limits"
                        :key="item"
                        @click="handleChangelength(item)"
                    >
                    <span>{{item}}</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>No</vs-th>
                <vs-th>Description</vs-th>
                <vs-th sort-key="params">Total Data</vs-th>
                <vs-th sort-key="file">File</vs-th>
                <vs-th sort-key="date">Date</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ indextr+1 }}</vs-td>
                    <vs-td :data="data[indextr].description">{{ data[indextr].description }}</vs-td>
                    <vs-td :data="data[indextr].total_data">{{ data[indextr].total_data }}</vs-td>
                    <vs-td :data="data[indextr].file"><a href="#" v-on:click="downloadFileAwsS3(tr.file)">File Uploaded</a></vs-td>
                    <vs-td :data="data[indextr].date">{{ data[indextr].date }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            style="padding-top:5px"
            :total="table.totalPage"
            v-model="setPage"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            uploadReady: true,
            table: {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 0,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0
            }
        };
    },
    methods: {
        handleDownload() {
            window.open(process.env.VUE_APP_CDN_ADDRESS+'/'+process.env.VUE_APP_CDN_BUCKET+'/templates/TemplateUserOMS.xlsx');
        },
        handleSubmit() {
            var file = this.$refs.file.files[0];
            if (file == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Form Validation",
                    text: "File is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                var dateNow = new Date();
                var now =
                    dateNow.getFullYear() +
                    "" +
                    (dateNow.getMonth() + 1) +
                    "" +
                    dateNow.getDate();
                now +=
                    +"" +
                    dateNow.getHours() +
                    "" +
                    dateNow.getMinutes() +
                    "" +
                    dateNow.getSeconds();
                this.formData = new FormData();
                this.formData.append("file", file);
                this.formData.append("user_agent", navigator.userAgent)
                this.formData.append("file_name", (now + "_" + file.name));
                this.formData.append("url", "/api/v1/import/user-bulk");
                this.$vs.loading();
                this.$http
                .post("/api/v1/import/user-bulk", this.formData, {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.status == "success") {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.uploadReady = false;
                        this.$nextTick(() => {
                            this.uploadReady = true;
                        });
                        this.getData()
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                })
                .catch((error) => {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: error,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                });
            }
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/import/history", {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    type: "user-bulk"
                }
            })
            .then(resp => {
                if (resp.status == "success") {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    console.log(resp.message);
                }
            });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }
            if (this.table.totalSearch < this.table.total) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
    },
    mounted() {},
    watch: {},
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            }
        }
    }
};
</script>
