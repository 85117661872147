<template>
  <vx-card title=" Bulk Update Master">
    <vs-tabs>
      <vs-tab label="Customer">
        <div class="tab-text">
          <customer></customer>
        </div>
      </vs-tab>
      <vs-tab label="User">
        <div class="tab-text">
          <user></user>
        </div>
      </vs-tab>
      <vs-tab label="Sales Area">
        <div class="tab-text">
          <sales-area></sales-area>
        </div>
      </vs-tab>
      <vs-tab label="Territory">
        <div class="tab-text">
          <territory></territory>
        </div>
      </vs-tab>
      <vs-tab label="Warehouse Territory">
        <div class="tab-text">
          <warehouse-territory></warehouse-territory>
        </div>
      </vs-tab>
      <vs-tab label="Zone">
        <div class="tab-text">
          <zone></zone>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Tax">
        <div class="tab-text">
          <tax></tax>
        </div>
      </vs-tab> -->
      <!-- <vs-tab label="Commission">
        <div class="tab-text">
          <commission></commission>
        </div>
      </vs-tab>
      <vs-tab label="Business Partner">
        <div class="tab-text">
          <business-partner></business-partner>
        </div>
      </vs-tab>
       -->
      <vs-tab label="Sales Channel">
        <div class="tab-text">
          <sales-channel></sales-channel>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import User from "./user";
import Territory from "./territory";
import SalesArea from "./sales_area";
import WarehouseTerritory from "./warehouse_territory";
import Commission from "./commission";
import Zone from "./zone";
import Tax from "./tax";
import SalesChannel from "./sales_channel";
import BusinessPartner from "./business_partner";
import Customer from "./customer";
export default {
  components: {
    User,
    Territory,
    SalesArea,
    WarehouseTerritory,
    Commission,
    Zone,
    Tax,
    SalesChannel,
    BusinessPartner,
    Customer
  }
};
</script>